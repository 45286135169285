import React, { useState } from 'react'
import { PageTemplate } from '../templates/page'
import { loadStripe } from '@stripe/stripe-js'
import { get, list, quantity, total } from 'cart-localstorage'
import Button from '../components/shortcodes/Button'
import CheckoutPageStyles from '../styles/CheckoutPage.module.sass'

const pageProps = {
  title: "Checkout",
  subtitle: "Pay online or go back and add more turkeys!",
  pageIdentifier: "checkout",
  pageClass: "hide-cart",
  ctaText: "Add More Birds"
}

const PaymentPage = () => {
  // Show error if cart is empty
  const [cart, setCart] = useState(typeof window !== 'undefined' ? list() : null)
  let subtotal = (typeof window !== 'undefined') ? total() : null

  // Prevent Gatsby from prerendering this component
  if (typeof window == 'undefined') {
    return false
  }

  // Increase/Decreate quantity
  function changeQty(operation, id) {
    if (operation === 'decrease') {
      quantity(id, -1)
    } else {
      if (get(id).quantity >= 50) {
        return false
      } else {
        quantity(id, 1)
      }
    }

    setCart(list())
  }

  if (cart && cart.length === 0) {
    return (
      <PageTemplate 
        title={pageProps.title}
        subtitle={pageProps.subtitle}
        pageIdentifier={pageProps.pageIdentifier}
        pageClass={pageProps.pageClass}
        CTAText={pageProps.ctaText}
      >
        <div className="has-text-centered">
          <div className="notification has-text-left">
            You can't proceed with the <strong>Checkout</strong> because you don't have a turkey in your bag!
          </div>
          <Button url="/" text="Choose a Bird" />
        </div>
      </PageTemplate>
    )
  }

  async function processForm(event) {
    event.preventDefault()

    let data = {}
    data['cart'] = cart
    data['total'] = subtotal

    let fields = event.target.querySelectorAll(
      '.field:not(.is-hidden) input, .field:not(.is-hidden) select'
    )

    for (let field of fields) {
      data[field.name] = field.value
    }

    const response = await fetch('/.netlify/functions/stripe-checkout', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }).then(res => res.json())

    const stripe = await loadStripe(response.publishableKey)

    const { error } = await stripe.redirectToCheckout({
      sessionId: response.sessionId,
    })

    if (error) {
      console.error(error)
    }
  }

  return (
    <PageTemplate 
        title={pageProps.title}
        subtitle={pageProps.subtitle}
        pageIdentifier={pageProps.pageIdentifier}
        pageClass={pageProps.pageClass}
        CTAText={pageProps.ctaText}
    >
      <h2>Your Bag</h2>
      <div className={CheckoutPageStyles.cartWidget || ''}>
        <table className={'table ' + (CheckoutPageStyles.table || '')}>
          <thead>
            <tr>
              <td>Product</td>
              <td>Price</td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            {cart.map(item => {
              return (
                <tr key={item.id}>
                  <td><img src="/media/bird-white.svg" alt="turkey" /><span>{item.name}</span></td>
                  <td>&euro;{item.price}</td>
                  <td>
                    <span
                      className={`tag ${CheckoutPageStyles.decrease || ''}`}
                      onClick={() => {
                        changeQty('decrease', item.id)
                      }}
                      onKeyDown={() => {
                        changeQty('decrease', item.id)
                      }}
                      role="button"
                      tabIndex="0"
                    >
                      x
                    </span>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      <h2>Place Your Order</h2>
      <form
        className={
          'columns is-multiline pay-form ' + (CheckoutPageStyles.form || '')
        }
        onSubmit={event => processForm(event)}
      >
        {
          // <div className="notification is-warning has-text-centered">
          //   ...Message...
          // </div>
        }
        <div className="field column is-half">
          <label htmlFor="name" className="label">
            Full Name
          </label>
          <div className="control">
            <input
              className="input"
              type="text"
              name="name"
              placeholder=""
              required
            />
          </div>
        </div>
        <div className="field column is-half">
          <label htmlFor="phone" className="label">
            Phone No.
          </label>
          <div className="control">
            <input
              className="input"
              type="tel"
              name="phone"
              placeholder=""
              pattern="[0-9 +]*"
              required
            />
          </div>
        </div>
        <div className="field column is-half">
          <label htmlFor="company" className="label">
            Company <small className="tag is-primary has-text-white">optional</small>
          </label>
          <div className="control">
            <input className="input" type="text" name="company" placeholder="" />
          </div>
        </div>
        <div className="field column is-half">
          <label htmlFor="email" className="label">
            E-mail address
          </label>
          <div className="control">
            <input
              className="input"
              type="email"
              name="email"
              placeholder=""
              required
            />
          </div>
        </div>
        <div className="column is-three-fifths is-offset-two-fifths">
          <div className={`notification is-warning is-light ${CheckoutPageStyles.totals || ''}`}>
            <p><strong>Total: <b>&euro;{~~subtotal}</b></strong></p>

            <button
            // disabled={loading}
            // onClick={redirectToCheckout}
            // className={`button ${ loading ? "is-light" : "is-link" }`}
              className={`button is-primary column is-full ${CheckoutPageStyles.submit || ''}`}
            >
              Pay Now
            </button>
          </div>
        </div>
        
      </form>
    </PageTemplate>
  )
}

export default PaymentPage
